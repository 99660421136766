.footer {
  background-color: #800020;
  color: wheat;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}

.company-name {
  margin-bottom: 10px;
  font-size: 24px;
}

.slogan {
  font-size: 14px;
  margin-bottom: 15px;
}

.contact-info p {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  justify-content: space-around;
}

.social-icon {
  color: wheat;
  font-size: 24px;
  text-decoration: none;
}

.social-icon:hover {
  color: #000;
}
