.mySwiper {
    width: 100%;
    height: 100vh;
    position: relative;
}

.mySwiper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(25%); /* Darken the image to 25% brightness */
}

.mytext {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: wheat;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    max-width: 90%;
    width: 100%;
}

.mytext h6 {
    font-size: 5rem;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

.mytext p {
    font-size: 1.2rem;
    margin: 0;
    padding: 10px 0 0 0;
    font-weight: 400;
}

.mybtn {
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: wheat;
    background-color: transparent; /* No background for the button */
    border: 1px solid wheat; /* Wheat border */
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.mybtn:hover {
    background-color: wheat; /* Button hover color */
    color: black; /* Button hover text color */
}

/* Responsive styles */
@media (max-width: 768px) {
    .mytext {
        padding: 15px;
    }

    .mytext h6 {
        font-size: 1.5rem;
    }

    .mytext p {
        font-size: 1rem;
    }

    .mybtn {
        padding: 8px 16px;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .mytext {
        padding: 10px;
    }

    .mytext h6 {
        font-size: 2.2rem;
    }

    .mytext p {
        font-size: 1.8rem;
    }

    .mybtn {
        padding: 6px 12px;
        font-size: 1.8rem;
    }
}
