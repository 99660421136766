.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1c1c1c;
    padding: 20px;
    color: wheat;
    position: relative;
    overflow: hidden;
}

.form-image-container {
    position: relative;
    width: 90%;
    max-width: 900px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    filter: brightness(50%);
}

.contact-form {
    position: absolute;
    background: rgba(0, 0, 0, 0.7);
    padding: 20px;
    border-radius: 10px;
    color: wheat;
    width: 80%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.contact-form h2 {
    color: wheat;
    margin-bottom: 20px;
    text-align: center;
}

.form-group {
    margin-bottom: 15px;
}

.form-group input, .form-group textarea {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: none;
    border-bottom: 2px solid wheat;
    background: transparent;
    color: wheat;
    outline: none;
}

.form-group textarea {
    resize: none;
    height: 100px;
}

.submit-btn {
    background-color: transparent;
    color: wheat;
    border: 2px solid wheat;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
    display: block;
    margin: 0 auto;
}

.submit-btn:hover {
    background-color: wheat;
    color: #1c1c1c;
}

@media (max-width: 768px) {
    .contact-form {
        width: 90%;
    }
}

@media (max-width: 480px) {
    .contact-form {
        width: 100%;
        padding: 15px;
    }

    .form-group input, .form-group textarea {
        padding: 8px;
    }

    .submit-btn {
        padding: 8px 15px;
    }
}
